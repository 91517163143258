<template>
  <div>
    <v-row>
      <v-col cols="12">
        <CorrectDataTable
          :table-headers="enquiry_header"
          :table-data="enquiry_list"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="total_items"
          :is-loading="load_table"
          :enable-search="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :disable-pagination="load_table"
          :disable-sort="true"
          @options-update="onTableChange"
        ></CorrectDataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { getData } from '@/api'
export default {
  name: 'Enquiry',
  components: {
    CorrectDataTable,
  },
  data: () => ({
    enquiry_header: [],
    enquiry_list: [],
    load_table: true,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    total_items: 0,
  }),
  methods: {
    initializeHeader() {
      this.enquiry_header = [
        {
          text: this.$t('job_post.create_date'),
          value: 'create_date',
        },
        {
          text: this.$t('worker.name'),
          value: 'name',
        },
        {
          text: this.$t('enquiry.contact_number'),
          value: 'phone',
        },
        {
          text: this.$t('worker.email'),
          value: 'email',
        },
        {
          text: this.$t('general.content'),
          value: 'content',
        },
      ]
    },
    async initializeData() {
      this.load_table = true
      try {
        let send_data = { page: this.tablePage - 1, limit: this.tablePageLimit }
        let params = new URLSearchParams()
        params.set('cms_get_contact_us', JSON.stringify(send_data))
        let get_enquiry_data = await getData(params)
        this.$func.log('-----Get Enquiry Data-----')
        this.$func.log(get_enquiry_data)

        if (!Array.isArray(get_enquiry_data)) {
          this.total_items = get_enquiry_data.total

          let enquiry_data = get_enquiry_data.data

          let temp = []
          for (let i = 0; i < enquiry_data.length; i++) {
            temp.push({
              id: enquiry_data[i].id,
              create_date: enquiry_data[i].create_date,
              name: enquiry_data[i].name,
              phone: enquiry_data[i].phone,
              email: enquiry_data[i].email,
              content: enquiry_data[i].content,
            })
          }

          this.enquiry_list = temp
        }
        this.load_table = false
      } catch (error) {
        this.$func.log(error)
        this.load_table = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.tablePageLimit = options.itemsPerPage
      this.initializeData()
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.initializeHeader()
      this.initializeData()
    }
  },
}
</script>